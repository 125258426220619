import GitHubCalendar from 'react-github-calendar';
import ReactTooltip from 'react-tooltip';

function Home (){

    const exampleTheme = {
        background: 'transparent',
        text: '#ffffff',
        grade4: 'hsl(118, 78%, 30%)',
        grade3: 'hsl(118, 78%, 46%)',
        grade2: 'hsl(118, 78%, 60%)',
        grade1: 'hsl(118, 78%, 80%)',
        grade0: '#000'
    };

    return (
        <div className="row">
            <div className="col-12 mt-5 text-center">
    
                <h3> GitHub Contributions</h3>
                <a href="https://github.com/AbeMont" className="mb-3 d-block" target="_blank">
                    <i class="fa fa-3 fa-github m-2" aria-hidden="true"></i>
                    @AbeMont
                </a>                            

                <GitHubCalendar 
                    username={'Abemont'} 
                    theme={exampleTheme}
                    blockSize={10}>
                    <ReactTooltip delayShow={50} html />
                </GitHubCalendar>
            </div>
        </div>
    )
}

export default Home;